<template>
  <div>
     <div class="recordadd-upload">
                <el-image v-if="filing_state==3&&province!=''" style="width: 160px;height: 160px"
                  :src="province"
                  :preview-src-list="[province]" alt=""></el-image>
                 <el-upload v-else-if="filing_state!=3&&imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" list-type="picture-card"
                  :file-list="fileList" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :on-success="handlepoll" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-upload v-else-if="filing_state!=3&&imageUrl!='1234567'" ref="upload" multiple action="" list-type="picture-card"
                  :file-list="fileList" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                  :http-request="(params) =>{return handlepoll(params)}" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                  :before-remove="beforeRemove" :class="{disabled:uploadDisabled}">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
                <div class="tc ">已开启OCR自动识别</div>
              </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from '../util/api'
import { local, aa, objKeySort } from '../util/util'
import { imageUrl } from '../config/index'
import { tengxuncloud } from '../util/aliOss'
export default {
  name: 'Area',
  props: {
     province:{
       type:String,
     },
     filing_state:{
       type:String,
     },
     area:{
       type:String,
     },
     town:{
       type:String,
     },
     vill:{
       type:String,
     },
     show1:{
       type:Number
     }
  },
  data () {
    return {
       morenUrl:require('../assets/image/moren.png'),
        imageUrl:imageUrl,
        dialogVisible:false,
        dialogImageUrl:false,
      uploadDisabled: false,
        fileList:[],
      cardData: {
        user_idcard_government:'',
        user_idcard_expirationtime:'',
        },
    }
  },
  computed:{
    // getData(){
    //   return this.province
    //   //  this.fileList = [{ name: '身份证头像面', url: this.province ? this.province : this.morenUrl }]; this.uploadDisabled = true;
    // }
  },
  created (){
    if(this.show1==1){
      if(this.province){
         this.fileList = [{ name: '身份证国徽面', url: this.province}];
         this.uploadDisabled = true;}else{
           this.fileList = [];
           this.uploadDisabled = false;} 
    }else{
      this.fileList=[]
    }
    
  },
 mounted () {
  
  },
  methods: {
      beforeAvatarUpload (file) {
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isLt10M;
    },
      handlepoll (response, file, fileList) {
      this.uploadDisabled = true;
      if(this.imageUrl=='1234567'){
        // this.$set(this.form4.accessory_list, 2, { accessory_type: '法人身份证', accessory_url: response.data.visit_url});
        
        this.getCards1(response.data.visit_url)
      }else{
        tengxuncloud(response).then((res) => {
          // this.$set(this.form4.accessory_list,2, { accessory_type: '法人身份证', accessory_url: res.url });
         this.getCards1(res.url)
         this.$emit("closeTip1",res.url)
      })
      }
    },
       getCards1(params){
      axios.get('/pc/baidu/orc/discern', { file_path: params, discern_type: '1', id_card_side: 'back' }).then(v => {
        if (JSON.stringify(v.data.discern_result) != '{}') {
          this.cardData.user_idcard_government = v.data.discern_result.user_idcard_government;
          this.cardData.user_idcard_expirationtime = v.data.discern_result.user_idcard_expirationtime;
           this.$emit("closeTip1",params,this.cardData)
        }else{
             this.$emit("closeTip1",params,this.cardData)
           this.$message({
            message: '请上传对应的身份证附件',
            type: 'warning',
            duration: 2 * 1000
        });
        }

      })
    },
    handleRemove (file, fileList) {
      this.cardData.user_idcard_government = '';
          this.cardData.user_idcard_expirationtime='';
      this.uploadDisabled = false;
      this.fileList=[]
      this.$emit("closeTip1",'',this.cardData)

    },
    beforeRemove (file, fileList) {
      if (file && file.status==="success") {
      return this.$confirm(`确定移除 ${file.name}吗？`);
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

  }
}
</script>

<style lang="scss" scoped>
.recordadd-upload {
    width: 160px;
    height: 160px;
    float: left;
    margin-right: 10px;
    .disabled {
      height: 100%;
    }
    /deep/ .el-upload {
      width: 160px;
      height: 160px;
      line-height: 160px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 160px;
      height: 160px;
    }
  }
</style>
