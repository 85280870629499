<template>
    <el-container class="shopList ">
        <el-header class="box add">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>个人中心</el-breadcrumb-item>
                <el-breadcrumb-item>基本信息</el-breadcrumb-item>
            </el-breadcrumb>
        </el-header>
        <el-main class="p15 pt15">
            <el-form :inline="true" status-icon ref="form1" :model="form1" class="demo-ruleForm">
                <el-row class="box mt20  f14">
                    <el-col :span="7" class="tl " :offset="1">
                        <el-form-item size="medium" label="身份证(头像面)：" class="w" label-width="41%"
                                      prop="accessory_list[0].accessory_url"
                                      :rules="[{required: true, message: '请上传身份证头像面', trigger: 'blur' }]">
                            <!-- <div class="recordadd-upload">
                              <el-upload v-if="imageUrl=='1234567'" ref="upload" multiple type="file" :file-list="fileList" action="/pc/file/upload"
                                list-type="picture-card" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                                :on-success="handlepoll"  :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                                :before-remove="beforeRemove" :class="{disabled:uploadDisabled}">
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-upload v-else ref="upload" multiple type="file" :file-list="fileList" action=""
                               :http-request="(params) =>{return handlepoll(params)}"
                                list-type="picture-card" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                                 :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                                :before-remove="beforeRemove" :class="{disabled:uploadDisabled}">
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                              </el-dialog>
                              <div class=" ">已开启OCR自动识别</div>
                            </div> -->
                            <Card @closeTip1='showAddress' v-if="showaccessory" :show1='1' :filing_state="filing_state"
                                  :province='form1.accessory_list[0].accessory_url'/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" class="tl ">
                        <el-form-item size="medium" label="身份证(国徽面)：" class="w" label-width="41%"
                                      prop="accessory_list[1].accessory_url"
                                      :rules="[{required: true, message: '请上传身份证国徽面', trigger: 'blur' }]">
                            <!-- <div class="recordadd-upload">
                              <el-upload v-if="imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" list-type="picture-card"
                                :file-list="fileList1" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                                :on-success="handlepoll1" :on-preview="handlePictureCardPreview" :on-remove="handleRemove1"
                                :before-remove="beforeRemove" :class="{disabled:uploadDisabled1}">
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-upload v-else ref="upload" multiple action="" list-type="picture-card"
                               :http-request="(params) =>{return handlepoll1(params)}"
                                :file-list="fileList1" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                               :on-preview="handlePictureCardPreview" :on-remove="handleRemove1"
                                :before-remove="beforeRemove" :class="{disabled:uploadDisabled1}">
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                              </el-dialog>
                              <div class=" ">已开启OCR自动识别</div>
                            </div> -->
                            <Card1 @closeTip1='showAddress1' v-if="showaccessory" :show1='1'
                                   :filing_state="filing_state" :province='form1.accessory_list[1].accessory_url'/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" class="tl ">
                        <el-form-item size="medium" label="健康证：" class="w" label-width="41%">
                            <Card2 @closeTip1='showAddress2' v-if="showaccessory" :show1='1'
                                   :filing_state="filing_state" :province='form1.accessory_list[2].accessory_url'/>
                            <!-- <div class="recordadd-upload">
                              <el-upload v-if="imageUrl=='1234567'" ref="upload" multiple action="/pc/file/upload" list-type="picture-card"
                                :file-list="fileList2" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                                :on-success="handlepoll2" :on-preview="handlePictureCardPreview" :on-remove="handleRemove2"
                                :before-remove="beforeRemove" :class="{disabled:uploadDisabled2}">
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-upload v-else ref="upload" multiple action="" list-type="picture-card"
                                :file-list="fileList2" :before-upload="beforeAvatarUpload" accept="image/png,image/jpg,image/jpeg"
                                :http-request="(params) =>{return handlepoll2(params)}"  :on-preview="handlePictureCardPreview" :on-remove="handleRemove2"
                                :before-remove="beforeRemove" :class="{disabled:uploadDisabled2}">
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                              </el-dialog>

                            </div> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class="mt30">
                        <el-form-item label="账号：" size="medium" class="w" label-width="28%" prop="user_mobilephone"
                                      :rules="[{required: true,message: '请输入账号',trigger: ['blur','change'] },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },{ maxlength: 50, message: '最多输入50个字符' }]">
                            <el-input placeholder="账号" maxlength='30' clearable v-model="form1.user_mobilephone"
                                      class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class=" mt30">
                        <el-form-item label="姓名：" size="medium" class="w" label-width="28%" prop="user_name"
                                      :rules="[{required: true,message: '请输入姓名',trigger: ['blur','change']},{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' },{ maxlength: 50, message: '最多输入50个字符' }]">
                            <el-input placeholder="姓名" maxlength='30' clearable v-model="form1.user_name"
                                      class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class="">
                        <el-form-item label="性别：" size="medium" class="w" label-width="28%">
                            <el-select v-model="form1.user_sex" clearable placeholder="性别">
                                <el-option v-for="(item,index) in options" :key="index" :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class="tl ">
                        <el-form-item class="w" label-width="28%" label="出生日期：" size="medium">
                            <el-date-picker type="date" clearable placeholder="出生日期" :picker-options='pickerOptionsEnd'
                                            v-model="form1.user_born" format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class="tl">
                        <el-form-item class="w" label-width="28%" label="身份证号：" size="medium" prop="user_idcard"
                                      :rules="[{required: true, message: '请输入身份证号',trigger: ['blur','change'] },{ min: 15, max: 18, message: '请如实填写15或者18位号码，以供核对',trigger: ['blur','change']},{ pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,message: '请输入正确的身份证号码',trigger: ['blur','change']}]">
                            <el-input placeholder="请输入18位身份证号码" clearable v-model="form1.user_idcard"
                                      class="input-with-select" @blur="selectCard(form1.user_idcard)">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class="tl ">
                        <el-form-item class="w" label-width="28%" label="身份证有效期：" size="medium">
                            <el-input placeholder="身份证有效期" maxlength='30' clearable
                                      v-model="form1.user_idcard_expirationtime"
                                      class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class=" ">
                        <el-form-item label="发证机关：" size="medium" class="w" label-width="28%">
                            <el-input placeholder="发证机关" maxlength='30' clearable v-model="form1.user_idcard_government"
                                      class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11" class="tl ">
                        <el-form-item class="w" label-width="28%" label="健康证有效期：" size="medium">
                            <el-date-picker type="date" clearable placeholder="健康证有效期" :picker-options='pickerOptions'
                                            v-model="form1.user_health_datedue" format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="11" class=" ">
                        <el-form-item label="详细地址：" size="medium" class="w" label-width="28%">
                            <el-input placeholder="如道路、门牌号、小区、楼栋号、单元室等（请勿重复录入省市区县信息）" maxlength='30' clearable
                                      v-model="form1.user_address"
                                      class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="22" class="tl">
                        <el-button class="ml_5" size="medium" type="primary" @click="submitForm('form1')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
    </el-container>
</template>
<script>
    import axios from '../../../util/api'
    import {tengxuncloud} from '../../../util/aliOss'
    import {local, aa, objKeySort} from '../../../util/util'
    import {imageUrl} from '../../../config/index'
    import Card from '../../../components/Card'
    import Card1 from '../../../components/Card1'
    import Card2 from '../../../components/Card2'
    // tengxuncloud(params).then((res) => {
    export default {
        components: {Card, Card1, Card2},
        data() {
            return {
                imageUrl: imageUrl,
                visit_url: '',
                morenUrl: require('../../../assets/image/moren.png'),
                user: '',
                loading: false,
                showaccessory: false,
                options: [{label: '男', value: '1'}, {label: '女', value: '2'}],
                show: true,
                filing_state: '',
                form1: {
                    user_mobilephone: '',
                    user_name: '',
                    user_sex: '',
                    user_born: '',
                    user_idcard: '',
                    user_idcard_government: '',
                    user_idcard_expirationtime: "",
                    user_health_datedue: '',
                    user_province: '',
                    user_city: '',
                    user_area: '',
                    user_town: "",
                    user_vill: "",
                    user_address: '',
                    accessory_list: [{accessory_type: "身份证头像面", accessory_url: ""},
                        {accessory_type: "身份证国徽面", accessory_url: ""},
                        {accessory_type: "健康证", accessory_url: ""}]
                },
                fileList: [],
                fileList1: [],
                fileList2: [],
                region_high_code: '',
                provinceTemp: "",
                cityTemp: "",
                areaTemp: "",
                townTemp: "",
                villTemp: "",
                provinceFlag: false,
                cityFlag: false,
                areaFlag: false,
                townFlag: false,
                villFlag: false,
                provinceOptions: [],
                cityOptions: [],
                areaOptions: [],
                townOptions: [],
                villOptions: [],
                uploadDisabled: false,
                uploadDisabled1: false,
                uploadDisabled2: false,
                uploadDisabled11: false,
                dialogImageUrl: '',
                dialogVisible: false,
                userData: {},
                pickerOptionsEnd: {
                    disabledDate: time => {
                        return time.getTime() > Date.now()
                    }
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now();
                    },
                }
            }
        },
        async mounted() {
            var that = this;
            that.userData.user_type = 3;
            await that.getData();
        },
        methods: {
            showAddress(val1, val2) {
                this.$set(this.form1.accessory_list, 0, {accessory_type: '身份证头像面', accessory_url: val1});
                this.form1.user_idcard = val2.user_idcard;
                this.form1.user_sex = val2.user_sex;
                this.form1.user_born = val2.user_born;
                this.form1.user_name = val2.user_name;
                this.form1.user_province = val2.user_province;
                this.form1.user_city = val2.user_city;
                this.form1.user_area = val2.user_area;
                this.form1.user_town = val2.user_town;
                this.form1.user_vill = val2.user_vill;
                this.form1.user_address = val2.user_address
                if (this.form1.user_idcard) {
                    this.getIdCard(this.form1.user_idcard)
                }
            },
            showAddress1(val1, val2) {
                this.$set(this.form1.accessory_list, 1, {accessory_type: '身份证国徽面', accessory_url: val1});
                this.form1.user_idcard_government = val2.user_idcard_government;
                this.form1.user_idcard_expirationtime = val2.user_idcard_expirationtime;
            },
            showAddress2(val1) {
                this.$set(this.form1.accessory_list, 2, {accessory_type: '健康证', accessory_url: val1});
            },
            getData() {
                var that = this;
                axios.get('/pc/user/single').then((v) => {
                    that.user = v.data.user;
                    that.form1.user_name = that.user.user_name;
                    that.form1.user_mobilephone = that.user.user_mobilephone;
                    that.form1.user_born = that.user.user_born;
                    that.form1.user_idcard = that.user.user_idcard;
                    that.form1.user_idcard_government = that.user.user_idcard_government;
                    that.form1.user_idcard_expirationtime = that.user.user_idcard_expirationtime;
                    that.form1.user_health_datedue = that.user.user_health_datedue;
                    that.form1.user_sex = String(that.user.user_sex);
                    that.form1.kf_openid = that.user.kf_openid;
                    that.form1.last_login_company_id = that.user.last_login_company_id;
                    that.form1.last_login_company_name = that.user.last_login_company_name;
                    that.form1.role_id = that.user.role_id;
                    that.form1.unionid = that.user.unionid;
                    that.form1.user_address = that.user.user_address;
                    that.form1.user_area = that.user.user_area;
                    that.form1.user_city = that.user.user_city;
                    that.form1.user_born = that.user.user_born;
                    that.form1.user_health_datedue = that.user.user_health_datedue;
                    that.form1.user_id = that.user.user_id;
                    that.form1.user_nation = that.user.user_nation;
                    that.form1.user_province = that.user.user_province;
                    that.form1.user_town = that.user.user_town;
                    that.form1.user_type = that.user.user_type;
                    that.form1.user_vill = that.user.user_vill;
                    that.user.accessory_list.map(m => {
                        switch (m.accessory_type) {
                            case "身份证正面":
                                that.form1.accessory_list[0]={
                                    accessory_type: "身份证头像面",
                                    accessory_url: m.accessory_url ? m.accessory_url : ''
                                }
                                break;
                            case "身份证背面":
                              that.form1.accessory_list[1]={
                                    accessory_type: "身份证国徽面",
                                    accessory_url: m.accessory_url ? m.accessory_url : ''
                                }
                                break;
                            case "健康证":that.form1.accessory_list[2]={
                              accessory_type: "健康证",
                              accessory_url: m.accessory_url ? m.accessory_url : ''
                             }
                              break;
                        }
                    })
                    this.showaccessory = true
                    that.handleUserLevel(that.userData, that.form1);

                })
            },
            submitForm(form1) {
                this.form1.user_province = this.provinceTemp ? this.provinceTemp.region_name : '';
                this.form1.user_city = this.cityTemp ? this.cityTemp.region_name : '';
                this.form1.user_area = this.areaTemp ? this.areaTemp.region_name : '';
                this.form1.user_town = this.townTemp ? this.townTemp.region_name : '';
                this.form1.user_vill = this.villTemp ? this.villTemp.region_name : '';
                this.$refs[form1].validate((valid) => {
                    if (valid) {
                        axios.put('/pc/user/info/update', this.form1).then((v) => {
                            local.set('user', this.form1);
                        })
                    } else {
                        this.$message.error('请检查表单必填项');
                        return false
                    }
                })
            },
            // beforeAvatarUpload (file) {
            //   const isLt2M = file.size / 1024 / 1024 < 2;

            //   if (!isLt2M) {
            //     this.$message.error('上传图片大小不能超过 2MB!');
            //   }
            //   return isLt2M;
            // },
            selectCard(val) {
                this.getIdCard(val)
            },
            getIdCard(params) {
                axios.get('/pc/region/idCard', {id_card: params}).then(v => {
                    this.form1.user_province = v.data.region_province
                    this.form1.user_city = v.data.region_city
                    this.form1.user_area = v.data.region_area
                    this.handleUserLevel(this.userData, this.form1);
                })
            },

            // handlepoll (response, file, fileList) {
            //   this.uploadDisabled = true;
            //   if(this.imageUrl=='1234567'){
            //      this.$set(this.form1.accessory_list, 0, { accessory_type: '身份证头像面', accessory_url: response.data.visit_url });
            //       this.getCarts(response.data.visit_url)
            //   }else{
            //     tengxuncloud(response).then((res) => {
            //       this.$set(this.form1.accessory_list, 0, { accessory_type: '身份证头像面', accessory_url: res.url });
            //      this.visit_url=res.url
            //      this.getCarts(this.visit_url)
            //    })
            //   }
            // },
            // getCarts(params){
            //    axios.get('/pc/baidu/orc/discern', { file_path:params, discern_type: '1', id_card_side: 'front' }).then(v => {
            //     if (JSON.stringify(v.data.discern_result) != '{}') {
            //       this.form1.user_idcard = v.data.discern_result.user_idcard;
            //       this.form1.user_sex = (v.data.discern_result.user_sex == '男' ? '1' : '2');
            //       this.form1.user_born = v.data.discern_result.user_born;
            //       this.form1.user_name = v.data.discern_result.user_name;
            //       this.form1.user_address = v.data.discern_result.user_address
            //       if(this.form1.user_idcard){
            //      this.getIdCard(this.form1.user_idcard)
            //      }

            //     }else{
            //        this.$message({
            //         message: '请上传对应的身份证附件',
            //         type: 'warning',
            //         duration: 2 * 1000
            //     });
            //     }

            //   })
            // },
            // handlepoll1 (response, file, fileList) {
            //   this.uploadDisabled1 = true;
            //   if(this.imageUrl=='1234567'){
            //      this.$set(this.form1.accessory_list, 1, { accessory_type: '身份证国徽面', accessory_url: response.data.visit_url});
            //       this.getCards1(response.data.visit_url)
            //   }else{
            //     tengxuncloud(response).then((res) => {
            //       this.$set(this.form1.accessory_list, 1, { accessory_type: '身份证国徽面', accessory_url: res.url });
            //      this.visit_url=res.url
            //      this.getCards1(this.visit_url)
            //    })
            //   }


            // },
            // getCards1(params){
            //   axios.get('/pc/baidu/orc/discern', { file_path: params, discern_type: '1', id_card_side: 'back' }).then(v => {
            //     if (JSON.stringify(v.data.discern_result) != '{}') {
            //       this.form1.user_idcard_government = v.data.discern_result.user_idcard_government;
            //       this.form1.user_idcard_expirationtime = v.data.discern_result.user_idcard_expirationtime;
            //     }else{
            //        this.$message({
            //         message: '请上传对应的身份证附件',
            //         type: 'warning',
            //         duration: 2 * 1000
            //     });
            //     }

            //   })
            // },
            // handlepoll2 (response, file, fileList) {
            //   this.uploadDisabled2 = true;
            //   if(this.imageUrl=='1234567'){
            //      this.$set(this.form1.accessory_list, 2, { accessory_type: '健康证', accessory_url: response.data.visit_url});
            //   }else{
            //     tengxuncloud(response).then((res) => {
            //       this.$set(this.form1.accessory_list, 2, { accessory_type: '健康证', accessory_url: res.url });
            //    })
            //   }


            // },

            // handleRemove (file, fileList) {

            //   this.uploadDisabled = false;
            //   this.$set(this.form1.accessory_list, 0, { accessory_type: '身份证头像面', accessory_url: '' });
            // },
            // handleRemove1 (file, fileList) {
            //   this.uploadDisabled1 = false;
            //   this.$set(this.form1.accessory_list, 1, { accessory_type: '身份证国徽面', accessory_url: '' });
            // },
            // handleRemove2 (file, fileList) {
            //   this.uploadDisabled2 = false;
            //   this.$set(this.form1.accessory_list, 2, { accessory_type: '健康证', accessory_url: '' });
            // },
            // handlePictureCardPreview (file) {
            //   this.dialogImageUrl = file.url;
            //   this.dialogVisible = true;
            // },
            // beforeRemove (file, fileList) {
            //   if (file && file.status==="success") {
            //      return this.$confirm(`确定移除 ${file.name}吗？`);
            //   }
            // },
            async handleUserLevel(userData, user) {
                if (parseInt(userData.user_type) > 0) {
                    this.provinceOptions = await this.fetchRegionData(1);

                    let province = this.provinceOptions.find(
                        item => item.region_name == (user.user_province ? user.user_province : userData.user_province)
                    );
                    this.provinceTemp = province ? province : {};
                    if (parseInt(userData.user_type) >= 1 || user.user_province) {
                        this.cityOptions = await this.fetchRegionData(
                            2,
                            province.region_code
                        );
                        let city = this.cityOptions.find(
                            item =>
                                item.region_name ==
                                (userData.user_city ? userData.user_city : user.user_city)
                        );
                        if (parseInt(userData.user_type) >= 2 || user.user_city) {
                            this.areaOptions = await this.fetchRegionData(3, city.region_code);
                            let area = this.areaOptions.find(
                                item =>
                                    item.region_name ==
                                    (userData.user_area ? userData.user_area : user.user_area)
                            );
                            this.cityTemp = city;
                            if (parseInt(userData.user_type) >= 3 || user.user_area) {
                                this.townOptions = await this.fetchRegionData(
                                    4,
                                    area.region_code
                                );
                                let town = this.townOptions.find(
                                    item =>
                                        item.region_name ==
                                        (userData.user_town ? userData.user_town : user.user_town)
                                );
                                this.areaTemp = area;
                                if (parseInt(userData.user_type) >= 4 || user.user_town) {
                                    this.villOptions = await this.fetchRegionData(
                                        5,
                                        town.region_code
                                    );
                                    let vill = this.villOptions.find(
                                        item =>
                                            item.region_name ==
                                            (userData.user_vill ? userData.user_vill : user.user_vill)
                                    );
                                    this.townTemp = town;
                                    if (parseInt(userData.user_type) >= 5 || user.user_vill) {
                                        this.villTemp = vill;
                                    }
                                }
                            }
                        }
                    }
                }
            },
            fetchRegionData(type, region_high_code) {
                let region_type = type.toString();
                return new Promise((resolve, reject) => {
                    axios
                        .get("/pc/region/all", {region_type, region_high_code})
                        .then(response => {
                            resolve(response.data.region_list);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
            },
            async handleChange(item, type) {
                let regionData;
                if (item.region_code == undefined) {
                    regionData = '';
                } else {
                    regionData = await this.fetchRegionData(type, item.region_code);
                }
                switch (type) {
                    case 2:
                        this.cityOptions = regionData;
                        this.form1.user_province = item.region_name;
                        this.areaOptions = [];
                        this.townOptions = [];
                        this.villOptions = [];
                        this.cityTemp = "";
                        this.areaTemp = "";
                        this.townTemp = "";
                        this.villTemp = "";
                        break;
                    case 3:
                        this.areaOptions = regionData;
                        this.form1.user_city = item.region_name;
                        this.townOptions = [];
                        this.villOptions = [];
                        this.areaTemp = "";
                        this.townTemp = "";
                        this.villTemp = "";
                        break;
                    case 4:
                        this.townOptions = regionData;
                        this.form1.user_area = item.region_name;
                        this.villOptions = [];
                        this.townTemp = "";
                        this.villTemp = "";
                        break;
                    case 5:
                        this.villOptions = regionData;
                        this.form1.user_town = item.region_name;
                        this.villTemp = "";
                        break;
                    case 6:
                        this.form1.user_vill = item.region_name;
                        break;
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .shopList {
        overflow-x: hidden;

        .add {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .active {
            background: #2ec68a;
            border-color: #2ec68a;
            color: #ffffff;
        }

        .back_btn {
            background: rgba(247, 248, 249, 1);
            border: 1px solid rgba(217, 222, 225, 1);
        }

        .recordadd-title {
            height: 34px;
            line-height: 34px;
            border-left: 2px solid #788287;
        }

        /deep/ .el-input-group__append .el-button--primary .el-icon-search {
            color: #fff;
        }

        /deep/ .el-checkbox-button__inner {
            border-left: 1px solid #dcdfe6;
            border-radius: 4px !important;
        }

        /deep/ .el-table.no-header .el-table__header {
            display: none;
        }

        .people-message .el-form-item {
            margin-bottom: 0px;
        }

        .recordadd-upload {
            width: 160px;
            height: 160px;
            float: left;
            margin-right: 10px;

            .disabled {
                height: 100%;
            }

            /deep/ .el-upload {
                width: 160px;
                height: 160px;
                line-height: 160px;
            }

            /deep/ .el-upload-list--picture-card .el-upload-list__item {
                width: 160px;
                height: 160px;
            }
        }

        .health_url {
            /deep/ .el-upload--picture-card {
                width: 70px;
                height: 40px;
                background-color: transparent;
                border: none;

                .el-button--medium {
                    position: absolute;
                    left: 10px;
                }
            }

            /deep/ .el-upload-list--picture-card .el-upload-list__item {
                width: 70px;
                height: 40px;
            }
        }

        /deep/ .el-col-7 .el-form-item__content {
            width: initial;
        }

        table .cell .is-error {
            padding-bottom: 20px;
        }

        .el-table th.is-leaf,
        .el-table td {
            border-bottom: none;
        }

        /deep/ .disabled .el-upload--picture-card {
            display: none !important;
        }

        /deep/ .el-table {
            .el-table__body,
            .el-table__header {
                width: 100% !important;
            }
        }

        .el-checkbox-button {
            margin-bottom: 10px;
        }
    }
</style>
